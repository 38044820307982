import React, { Component } from 'react';
import Stopwatch from './components/Stopwatch';
import IntroHeader from './components/IntroHeader';
import FeatureDescription from './components/FeatureDescription';
import './App.css';
import CTAButton from './components/CTAButton';
import AppDescription from './components/AppDescription';

class App extends Component {
  state = {
    stopwatches: [{ id: Date.now() }], // Default stopwatch instance is created.
  };

  createStopwatch = () => {
    const newStopwatch = { id: Date.now() };
    this.setState((prevState) => ({
      stopwatches: [...prevState.stopwatches, newStopwatch],
    }));
  };

  deleteStopwatch = (id) => {
    this.setState((prevState) => ({
      stopwatches: prevState.stopwatches.filter((sw) => sw.id !== id),
    }));
  };

  render() {
    const { stopwatches } = this.state;

    return (
      <div className="app">
      <IntroHeader onClick={this.createStopwatch} />
        {stopwatches.map((stopwatch) => (
          <Stopwatch key={stopwatch.id} id={stopwatch.id} onDelete={this.deleteStopwatch} />
        ))}
        <CTAButton onClick={this.createStopwatch} />
        <div className='descriptions'>
          <AppDescription />
          <FeatureDescription />

        </div>

      </div>
    );
  }
}

export default App;
