import React from 'react';
import './IntroHeader.css'; // Corrected the import statement

function IntroHeader({ onClick }) {
  return (
    <div className="intro-header-container">
      <h1 className="intro-title">Multiple Stopwatches App</h1>

    </div>
  );
}

export default IntroHeader;
