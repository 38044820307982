import React from 'react';
import './CTAButton.css';

function CTAButton({ onClick }) {
  return (
    <button className="cta-button" onClick={onClick}>
      + Create New Stopwatch
    </button>
  );
}

export default CTAButton;
