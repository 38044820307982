import React, { Component } from 'react';
import Button from './Button';
import './Stopwatch.css';

class Stopwatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: 'Stopwatch',
      isRunning: false,
      startTime: null,
      offsetTime: 0,
      totalTimePaused: 0,
      lastPauseTime: null,
      elapsedTime: 0,
      laps: [],
      isEditingLabel: false,
      lastUpdated: Date.now(),
    };
    this.timerInterval = null;
  }

  static formatTime(time) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    const milliseconds = Math.floor((time * 10) % 10);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds}`;
  }

  saveStopwatchState = () => {
    const { isRunning, elapsedTime } = this.state;
    const stopwatchState = {
      isRunning,
      elapsedTime,
    };
    localStorage.setItem(`stopwatchState_${this.props.id}`, JSON.stringify(stopwatchState));
  };

  loadStopwatchState = () => {
    const savedState = JSON.parse(localStorage.getItem(`stopwatchState_${this.props.id}`));
    if (savedState) {
      this.setState(savedState);
    }
  };

  componentDidMount() {
    this.loadStopwatchState();
  }

  componentWillUnmount() {
    this.saveStopwatchState();
    clearInterval(this.timerInterval);
  }

  toggleTimer = () => {
    if (this.state.isRunning) {
      clearInterval(this.timerInterval);
      this.setState(prevState => ({
        isRunning: false,
        offsetTime: prevState.elapsedTime,
        startTime: null
      }));
    } else {
      this.timerInterval = setInterval(this.updateElapsedTime, 10);
      this.setState({ 
        startTime: Date.now(),
        isRunning: true
      });
    }
  };
  
  updateElapsedTime = () => {
    const { startTime, offsetTime } = this.state;
    const currentTime = Date.now();
    const elapsedTime = offsetTime + (currentTime - startTime) / 1000;
    this.setState({ elapsedTime });
    this.saveStopwatchState();
  };
  

  recordLap = () => {
    const { elapsedTime, laps } = this.state;
    const lapTime = Stopwatch.formatTime(elapsedTime);
    const updatedLaps = [...laps, lapTime];
    this.setState({ laps: updatedLaps });
  };

  resetTimer = () => {
    clearInterval(this.timerInterval);
    this.setState({ elapsedTime: 0, laps: [], isRunning: false, offsetTime: 0, startTime: null });
  };

  deleteStopwatch = () => {
    clearInterval(this.timerInterval);
    localStorage.removeItem(`stopwatchState_${this.props.id}`);
    if (this.props.onDelete) {
      this.props.onDelete(this.props.id);
    }
  };

  handleEditClick = () => {
    this.setState({ isEditingLabel: true });
  };

  handleLabelChange = (e) => {
    this.setState({ label: e.target.value });
  };

  handleLabelSave = () => {
    this.setState({ isEditingLabel: false });
  };



  render() {
    const { label, isRunning, elapsedTime, laps, isEditingLabel } = this.state;
    return (
      <div className="stopwatch-container">
        <div className="label-container">
          {isEditingLabel ? (
            <input
              type="text"
              value={label}
              onChange={this.handleLabelChange}
              onBlur={this.handleLabelSave}
              onKeyPress={e => e.key === 'Enter' && this.handleLabelSave()}
              autoFocus
            />
          ) : (
            <>
              <span className="stopwatch-label" onClick={this.handleEditClick}>
                {label}
              </span>
              <i
                className="fa fa-pencil edit-icon"
                onClick={this.handleEditClick}
                style={{ alignItems: 'center', marginBottom: '10px' }}
              ></i>
            </>
          )}
        </div>

        <div className="elapsed-time">
          <div className="time-display">{Stopwatch.formatTime(elapsedTime)}</div>
        </div>

        <div className="button-container">
          <Button
            className="control-button"
            label={isRunning ? 'Pause' : 'Play'}
            onClick={this.toggleTimer}
          />
          <Button className="control-button" label="Lap" onClick={this.recordLap} />
          <Button className="control-button" label="Reset" onClick={this.resetTimer} />
          <Button className="delete-button" label="Delete" onClick={this.deleteStopwatch} />
        </div>

        <ul className="lap-list">
          {laps.map((lap, index) => (
            <li key={index}>
              Lap {index + 1}: {lap}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Stopwatch;
