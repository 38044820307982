import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import './FeatureDescription.css';

function FeatureDescription() {
  const [isExpanded, setExpand] = useState(false);

  const toggleExpand = () => {
    setExpand(!isExpanded);
  };

  return (
    <div className="feature-container">
      <span onClick={toggleExpand} className="question">
        Who is Multiple Stopwatches for?
        <span className={`icon ${isExpanded ? "down-arrow" : ""}`}>
          <IoIosArrowForward />
        </span>
      </span>
      {isExpanded && (
        <div className="answer">
          <ul className="feature-list">
            <li>🏡 For the Remote Work Maestro: Seamlessly keep track of back-to-back meetings, quick stretches, or brewing the perfect cup of coffee.</li>
            <li>🧘 For the Mindful Meditator: Dive deeper into your sessions, with timers that respect your flow. Be in sync with every heartbeat.</li>
            <li>💪 For the Passionate Fitness Buff: Track your workouts, hydration reminders, and rest intervals all in one place.</li>
            <li>📚 For the Aspiring Scholar: Manage your research, revision, or relaxation rhythms effortlessly.</li>
            <li>🌱 For Those Seeking Balance: Our discreet timers stand by you on your journey of self-awareness, healing, or growth.</li>
          </ul>
          <p className="feature-summary">
            Our distinction? The gift of tracking multiple facets of your life, all at once. Every moment is an opportunity, and you deserve to capture them all.
          </p>
        </div>
      )}
    </div>
  );
}

export default FeatureDescription;
