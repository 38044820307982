import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import './AppDescription.css';

function AppDescription() {
  const [isExpanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className="description-container">
      <span onClick={toggleExpand} className="question">
        What Motivates Us
        <span className={`icon ${isExpanded ? "expanded-icon" : ""}`}>
          <IoIosArrowForward />
        </span>
      </span>
      {isExpanded && (
        <div className="answer">
          Harness Every Second, Every Breath. In a fast-paced world where juggling
          multiple tasks has become the norm, the Multiple Stopwatches App empowers
          you to seize control of your time. With seamless tracking, intuitive management,
          and precision timing, you can make the most of every moment.
        </div>
      )}
    </div>
  );
}

export default AppDescription;
